import {canvasApp} from '@/components/Canvas/Application/Application'
import {DateRangeSelector} from '@/components/Drawer/ContentParams/DateRangeSelector'
import DrawerBottomButtons from '@/components/Drawer/ContentParams/DrawerBottomButtons'
import {JourneyTypeSelector} from '@/components/Drawer/ContentParams/JourneyTypeSelector'
import {RecurrenceSelector} from '@/components/Drawer/ContentParams/RecurrenceSelector'
import {TimeExecutionsSelector} from '@/components/Drawer/ContentParams/TimeExecutionsSelector'
import {useJourneyDrawer} from '@/components/Drawer/hooks/useJourneyDrawer'
import {useJourneyConfigEditor} from '@/components/JourneyConfigEditor/useJourneyConfigEditor.hook'
import {CountrySelector} from '@/components/JourneyEditor/components/CountrySelector'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {useCountries} from '@/hooks/useCountries'
import {useJourneyMetaConfig} from '@/hooks/useJourneyMetaConfig'
import {useTimezone} from '@/hooks/useTimezone'
import {InputSelector} from '@/shared-components/InputSelector/InputSelector'
import {InputText} from '@/shared-components/InputText'
import {InputTextArea} from '@/shared-components/InputTextArea'
import {NodeTypeEnum} from '@/types/NodeTypeEnum'
import {TargetAudience} from '@/types/TargetAudience'
import {resetJourneyDrawer, setCurrentNode} from '@ReduxActions'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useAppDispatch} from '../../store'

export const JourneyConfigEditor = () => {
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()
  const {onCloseDrawer} = useJourneyDrawer()
  const {status, isPublishedOrCompleted, isNew: isNewJourney} = useJourneyMetaConfig()
  const {
    onNameChanged,
    onTargetAudienceChanged,
    onDescriptionChanged,
    onCountryChanged,
    onPeriodDidChange,
    onRecurrenceDidChange,
    onTimeExecutionsDidChange,
    onRecurrenceDaysDidChange,
    temporaryConfig,
    hasUnsavedChanges,
    isAllRequiredFilled,
    saveTemporaryChanges,
    onJourneyTypeDidChange,
  } = useJourneyConfigEditor()
  const {tz} = useTimezone()

  const {countries} = useCountries()

  const confirmChangesAndCloseDrawer = useCallback(() => {
    saveTemporaryChanges(() => {
      dispatch(resetJourneyDrawer())

      if (isNewJourney) {
        const id = canvasApp.getNodes().first.getID()
        dispatch(setCurrentNode({id, type: NodeTypeEnum.entryCondition}))
      }
    })
  }, [saveTemporaryChanges, isNewJourney, dispatch])

  if (isPublishedOrCompleted) {
    return null
  }

  const targetAudienceOptions = Object.values(TargetAudience).map(item => {
    return (
      <option value={item} key={item}>
        {formatMessage({id: `TARGET_AUDIENCE.${item}`})}
      </option>
    )
  })

  return (
    // mb-16 is the DrawerBottomButtons height
    <div className="mb-16 flex flex-col gap-6" data-testid="journey-config-editor">
      <div className="flex flex-col gap-2 px-1 text-text-secondary">
        <p className="text-base font-bold">{formatMessage({id: 'JOURNEY_BUILDER.EDITOR_HEADER'})}</p>
        <p className="text-sm font-normal">{formatMessage({id: 'JOURNEY_BUILDER.EDITOR_SUBHEADER'})}</p>
      </div>

      <div
        className="items-left flex h-fit w-full max-w-[678px] flex-col gap-6 overflow-auto px-1"
        data-testid="journey-meta-config"
      >
        <div className="flex w-full max-w-xs flex-col gap-1">
          <CountrySelector
            id="journey-country"
            countries={countries}
            label={formatMessage({id: 'JOURNEY_BUILDER.COUNTRY'})}
            status={status}
            value={temporaryConfig.country}
            onValueChanged={onCountryChanged}
          />
        </div>
        <div className="h-42 flex w-full flex-wrap gap-6">
          <div className="w-full max-w-[321px] gap-2">
            <InputText
              type="text"
              placeholder={formatMessage({id: 'JOURNEY_BUILDER.NAME_INPUT_PLACEHOLDER'})}
              value={temporaryConfig.name}
              onChange={onNameChanged}
              label={formatMessage({id: 'JOURNEY_BUILDER.NAME_LABEL'})}
              error={
                !temporaryConfig.name.length ? formatMessage({id: 'JOURNEY_BUILDER.REQUIRED_LABEL_ERROR'}) : undefined
              }
              maxLength={36}
              data-testid="journey-name"
              data-cy="journey-name-config"
              disabled={isPublishedOrCompleted}
              autoFocus
              required
            />
          </div>
          {isFeatureEnabled(FeatureToggle.targetAudience) && (
            <div className="w-full max-w-[321px] gap-2">
              <InputSelector
                placeholder={formatMessage({id: 'JOURNEY_BUILDER.TARGET_AUDIENCE_PLACEHOLDER'})}
                value={temporaryConfig.targetAudience ?? TargetAudience.userLevel}
                onChange={onTargetAudienceChanged}
                label={formatMessage({id: 'JOURNEY_BUILDER.TARGET_AUDIENCE_LABEL'})}
                id="journey-target-audience"
                disabled={isPublishedOrCompleted}
                autoFocus
                required
              >
                <option hidden>{formatMessage({id: 'JOURNEY_BUILDER.SELECT_TARGET_AUDIENCE'})}</option>
                {targetAudienceOptions}
              </InputSelector>
            </div>
          )}
        </div>
        <JourneyTypeSelector
          status={status}
          onJourneyTypeDidChange={onJourneyTypeDidChange}
          type={temporaryConfig.type}
        />
        <div className="h-42 flex w-full max-w-xs flex-col gap-1">
          <InputTextArea
            label={formatMessage({id: 'JOURNEY_BUILDER.DESCRIPTION_INPUT_LABEL'})}
            placeholder={formatMessage({id: 'JOURNEY_BUILDER.DESCRIPTION_INPUT_PLACEHOLDER'})}
            value={temporaryConfig.description}
            onChange={onDescriptionChanged}
            maxLength={100}
            id="journey-description"
            disabled={isPublishedOrCompleted}
          />
        </div>

        {isFeatureEnabled(FeatureToggle.scheduleDateRange) && (
          <DateRangeSelector
            timezone={tz}
            start={temporaryConfig.period?.start}
            end={temporaryConfig.period?.start ? temporaryConfig.period?.end : undefined}
            onChange={onPeriodDidChange}
            disabled={isPublishedOrCompleted}
            type={temporaryConfig.type}
          />
        )}

        <RecurrenceSelector
          id="journey-recurrence"
          value={temporaryConfig.recurrence}
          onChange={onRecurrenceDidChange}
          disabled={isPublishedOrCompleted}
          className="w-full max-w-xs"
          type={temporaryConfig.type}
          recurrenceDays={temporaryConfig.recurrenceDays}
          onRecurrenceDaysDidChange={onRecurrenceDaysDidChange}
        />

        <TimeExecutionsSelector
          id="journey-time-execution-windows"
          value={temporaryConfig.timeExecutionWindows}
          onChange={onTimeExecutionsDidChange}
          disabled={isPublishedOrCompleted}
        />
      </div>

      <DrawerBottomButtons
        testIdPrimary="continue-or-save-button"
        testIdSecondary="cancel-button"
        onClickPrimary={confirmChangesAndCloseDrawer}
        onClickSecondary={onCloseDrawer}
        primaryButtonLabel={formatMessage({
          id: isNewJourney ? 'JOURNEY_BUILDER.CONTINUE_BUTTON' : 'GENERAL.SAVE_BUTTON',
        })}
        secondaryButtonLabel={formatMessage({id: 'JOURNEY_BUILDER.CANCEL_BUTTON'})}
        isPrimaryDisabled={!isAllRequiredFilled || !hasUnsavedChanges}
      />
    </div>
  )
}
