import {RecurrenceDaySchema} from '@/types/Day'
import {JourneyTypeSchema} from '@/types/JourneyType'
import {PeriodSchema} from '@/types/Period'
import {RecurrenceSchema} from '@/types/Recurrence'
import {TargetAudience} from '@/types/TargetAudience'
import {CountryCodeSchema} from '@/types/Templates'
import {TimeExecutionWindowsSchema} from '@/types/TimeExecution'
import {z} from 'zod'
import {JourneyStatus} from './JourneyStatus'
import {PageableResponseSchema} from './Pagination'
import {StepEntryConditionSchema, StepsSchema} from './Steps'

// MARK: - Journey template config

const JourneySchema = z.object({
  id: z.string(),
  name: z.string().max(100),
  description: z.string().max(100).optional(),
  country: CountryCodeSchema,
  status: z.nativeEnum(JourneyStatus),
  type: JourneyTypeSchema.optional(),
  recurrence: RecurrenceSchema.optional(),
  recurrenceDays: RecurrenceDaySchema.optional(),
  period: PeriodSchema.optional(),
  timeExecutionWindows: TimeExecutionWindowsSchema.optional(),
  entryCondition: StepEntryConditionSchema,
  steps: StepsSchema,
  publishedAt: z.string().nullable().optional(),
  targetAudience: z.nativeEnum(TargetAudience).nullish(),
})
export type Journey = z.infer<typeof JourneySchema>

// MARK: - Journeys

const JourneyListItemSchema = z.object({
  id: JourneySchema.shape.id,
  name: JourneySchema.shape.name,
  description: JourneySchema.shape.description,
  country: JourneySchema.shape.country,
  status: JourneySchema.shape.status,
  type: JourneySchema.shape.type.optional(),
  createdAt: z.string(),
  updatedAt: z.string().nullable().optional(),
  publishedAt: z.string().nullable().optional(),
})
export type JourneyListItem = z.infer<typeof JourneyListItemSchema>

export const GetJourneysRequestSchema = z.object({
  page: z.number().optional(),
  size: z.number().optional(),
  sort: z.string().optional(),
})
export const GetJourneysResponseSchema = PageableResponseSchema(JourneyListItemSchema)

export type GetJourneysRequest = z.infer<typeof GetJourneysRequestSchema>
export type GetJourneysResponse = z.infer<typeof GetJourneysResponseSchema>

// MARK: - Single Journey by id

export const GetJourneyRequestSchema = z.void()
export const GetJourneyResponseSchema = JourneySchema

export type GetJourneyRequest = z.infer<typeof GetJourneyRequestSchema>
export type GetJourneyResponse = z.infer<typeof GetJourneyResponseSchema>

// MARK - Delete journey by id

export const DeleteJourneyRequestSchema = z.void()
export const DeleteJourneyResponseSchema = JourneySchema

export type DeleteJourneyResponse = z.infer<typeof JourneySchema>

// MARK: - New Journey

export const SaveJourneyRequestSchema = JourneySchema.extend({
  id: z.optional(JourneySchema.shape.id),
  status: z.optional(JourneySchema.shape.status),
})

export const SaveJourneyResponseSchema = z.object({
  id: JourneySchema.shape.id,
  status: JourneySchema.shape.status,
})

export type SaveJourneyRequest = z.infer<typeof SaveJourneyRequestSchema>
export type SaveJourneyResponse = z.infer<typeof SaveJourneyResponseSchema>

// MARK: - Reports responses

export const GetReportResponseSchema = z.object({
  path: z.string(),
})
export type GetReportResponse = z.infer<typeof GetReportResponseSchema>

export const GetJourneyReportRequestSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
})
export type GetJourneyReportRequest = z.infer<typeof GetJourneyReportRequestSchema>

export const ReportWorksheetSchema = z.object({
  name: z.string(),
  columns: z.array(z.object({name: z.string()})),
  records: z.array(z.record(z.string(), z.unknown().nullable())),
})

export type ReportWorksheet = z.infer<typeof ReportWorksheetSchema>

export const GetReportDataResponseSchema = z.object({
  content: z.array(ReportWorksheetSchema),
})
export type GetReportDataResponse = z.infer<typeof GetReportDataResponseSchema>

export type GetJourneyReportDataRequest = z.infer<typeof GetJourneyReportRequestSchema>

// MARK: - Generic responses

export const NoContentResponseSchema = z.string()
export type NoContentResponse = z.infer<typeof NoContentResponseSchema>
