import {getJourneysService} from '../services/journeysService'
import {GetJourneysResponse} from '../types/Journeys'

export type GetJourneysUseCaseParams = {
  page?: number
  size?: number
  sort?: string
}

export const DEFAULT_SORT = 'createdAt,desc'

export const DEFAULT_PAGE_SIZE = 10

export async function getJourneysUseCase(
  params?: GetJourneysUseCaseParams,
  signal?: AbortSignal
): Promise<GetJourneysResponse> {
  const {page = 0, size = DEFAULT_PAGE_SIZE, sort = DEFAULT_SORT} = params || {}
  return getJourneysService({page, size, sort}, signal)
}
